/* eslint-disable no-param-reassign */
import { findNodes, withSuggestions } from '../../../../components/Tree/helpers';
import { getCyclicIndex } from '../../../../utils/array';
import { getCatalogId } from '../../../../utils/tree/ids';
import { CATALOG_TYPE_UNCATEGORIZED } from '../../../../utils/types/catalogTypes';

export function highlightSuggestedCategoriesReducer(state, action) {
  const {
    meta: { tree },
    payload: { highlight = true, highlightItem },
  } = action;
  const catalogId = getCatalogId(tree);
  const categoryIds =
    state.selectedNodeItem[
      CATALOG_TYPE_UNCATEGORIZED
    ]?.node?.suggestion?.categories?.map((c) => c.categoryId) || [];
  const treeData = state.catalogs[catalogId]?.treeData || [];
  const searchMethod = highlight
    ? (ni) => categoryIds.includes(ni.node.categoryId)
    : () => false;
  const { matches } = findNodes({ treeData, searchMethod });
  const idx = getHighlightIndex(state.catalogs[catalogId], highlightItem, matches);
  const highlightFocusId = matches[idx]?.node.id;
  state.catalogs[catalogId].treeData = withSuggestions({
    treeData,
    matches,
    focusId: highlightFocusId,
    expandMatches: true,
  });
  state.catalogs[catalogId].highlightIndex = highlight ? idx : undefined;
  state.catalogs[catalogId].highlightFocusId = highlight
    ? highlightFocusId
    : undefined;
}

function getHighlightIndex(currentState, highlightItem, matches) {
  if (!matches.length) {
    return 0;
  }

  let { highlightIndex = -1 } = currentState;
  if (highlightItem === '+') {
    highlightIndex += 1;
  } else if (highlightItem === '-') {
    highlightIndex -= 1;
  } else {
    highlightIndex = 0;
  }

  const idx = getCyclicIndex(matches, highlightIndex);
  return idx;
}
