import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { store } from './store';
import { mergeState } from './store/actions/app/state';
import './utils/welcome';
import './styles/index.scss';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_SCOPE,
} from './utils/auth0/constants';

window.addEventListener('popstate', async (eventArgs) => {
  const { state } = eventArgs;
  // log('popstate', state);
  store.dispatch(mergeState(state));
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    cacheLocation="memory"
    useRefreshTokens
    authorizationParams={{
      redirect_uri: window.location.origin,
      scope: AUTH0_SCOPE,
      audience: AUTH0_AUDIENCE,
    }}
  >
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </Auth0Provider>,
);
