import React from 'react';
import { ENVIRONMENT_STAMP } from '../../utils/env';
import ToggleThemeButton from '../buttons/ToggleThemeButton';
import UserProfileButton from '../buttons/UserProfileButton';
import ApplicationName from './ApplicationName';
import styles from './Header.module.scss';
import ReportsLink from '../links/ReportsLink';
import KunskapLink from '../links/KunskapLink';
import ExportButton from '../buttons/ExportButton';
import MarketSelectorButton from '../buttons/MarketSelectorButton';
import { SkipToMain } from './SkipToMain';

const style = ENVIRONMENT_STAMP
  ? { backgroundColor: 'rgb(var(--color-environment-stamp))' }
  : undefined;

const Header = () => (
  <div className={styles.headerComponent} style={style}>
    <SkipToMain />
    <ApplicationName />
    <div className={styles.group}>
      <ReportsLink />
      <KunskapLink />
      <ExportButton />
      <MarketSelectorButton />
      <UserProfileButton />
      <ToggleThemeButton />
    </div>
  </div>
);

export default Header;
