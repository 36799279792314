import React from 'react';
import { selectIsGlobalMarket } from '../../store/slices/intl';
import { useSelector } from 'react-redux';
import styles from './GlobalGuard.module.scss';

export const GlobalGuard = ({ children }: { children: React.ReactNode }) => {
  const isGlobalMarket = useSelector(selectIsGlobalMarket);

  if (!isGlobalMarket)
    return (
      <div className={styles.container}>
        <em>
          Oops! This page is only availabe for global market. <br />
          Please change to global market or navigate to another page...
        </em>
      </div>
    );

  return children;
};
