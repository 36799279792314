import Select, { Option } from '@ingka/select';
import { useSelector } from 'react-redux';
import { selectMarketsGroupedPerAccess } from '../../store/selectors/market/grouped';
import { GLOBAL } from '../../utils/market';

type MarketOverride = {
  countryName: string;
  languageCodes: string[];
  retailUnit: string;
};

export const MarketSelect = ({
  id,
  onChange,
  retailUnit,
  languageCode,
  showGlobal = false,
}: {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showGlobal?: boolean;
  retailUnit: string;
  languageCode: string;
}) => {
  const { editorMarkets, viewerMarkets } = useSelector(
    selectMarketsGroupedPerAccess,
  );

  if (!editorMarkets?.length && !viewerMarkets?.length) return null;

  const removeGlobal = (market: MarketOverride) => market.retailUnit !== GLOBAL;

  const langCodeMap = (market: MarketOverride) => ({
    value: `${market.retailUnit}/${market.languageCodes[0]}`,
    name: market.countryName,
  });

  const em = showGlobal
    ? editorMarkets.map(langCodeMap)
    : editorMarkets.filter(removeGlobal).map(langCodeMap);
  const vm = viewerMarkets.filter(removeGlobal).map(langCodeMap);

  const options = [...em, ...vm];
  const value = retailUnit === GLOBAL ? '' : `${retailUnit}/${languageCode}`;

  return (
    <Select
      hintText="select market"
      value={value}
      id={id}
      onChange={onChange}
      label="Market"
    >
      <>
        {options.map((market) => (
          <Option key={market.value} name={market.name} value={market.value} />
        ))}
      </>
    </Select>
  );
};
