import { fetchCatalogs } from '../catalog/fetchCatalogs';
import { setMarket } from '../intl';
import { closeModal, openModal } from '.';
import { resetPanes } from '../../slices/pane';

export const closeMarketSelector = () => closeModal();

export const selectMarketAndClose =
  (retailUnit, languageCode) => async (dispatch) => {
    dispatch(resetPanes());
    await dispatch(setMarket({ retailUnit, languageCode }));
    await dispatch(fetchCatalogs());
    return dispatch(closeModal());
  };

export const openMarketSelector = () => openModal('MarketDialog');
