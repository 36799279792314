import React from 'react';
import cx from 'classnames';
import CatalogIcon from '../../../Icons/CatalogIcon';
import styles from './TreeSelectorNode.module.scss';

const TreeSelectorNode = function TreeSelectorNode({
  catalog,
  text,
}: {
  catalog: {
    catalogType: string;
    meta: {
      [key: string]: string;
    };
  };
  text: string;
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <span className={styles.icon}>
          <CatalogIcon className={styles.icon} catalogType={catalog.catalogType} />
        </span>
        <span className={styles.text}>{text}</span>
      </div>
      <div className={cx(styles.block, styles.status)}>
        <span className={styles.icon} />
        <span className={styles.meta}>
          {catalog.meta &&
            Object.entries(catalog.meta)
              .filter(([, value]) => !!value)
              .map(([key, value]) => (
                <React.Fragment key={key}>{value}</React.Fragment>
              ))}
        </span>
      </div>
    </div>
  );
};

export default TreeSelectorNode;
