import { useCallback, useEffect, useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Tooltip from '@ingka/tooltip';
import { z } from 'zod';
import { useDispatch, useSelector } from 'react-redux';
import { getUnHiddenCategories } from '../../../store/actions/api/reports';
import { AppThunkDispatch } from '../../../store';
import { AutoUnHiddenCategoriesReportsSchema } from '../../../schemas/categories';
import { toDateTimeString } from '../../../utils/date';
import { MarketSelect } from '../MarketSelect';
import { masterCatalogIdSelector } from '../../../store/selectors/catalog/ids';
import styles from '../Reports.module.scss';
import {
  sortOrReverse,
  sortOrReverseDate,
} from '../../../utils/array/sortOrReverse';
import { useParams } from 'react-router-dom';
import { REPORTS_SKIP_TO } from '../constants';

type UnHiddenItems = z.infer<typeof AutoUnHiddenCategoriesReportsSchema>;

type SortKeys = keyof UnHiddenItems[number];

const TruncateComment = ({ comment }: { comment: string | null }) => {
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  if (!comment?.length) return <td />;

  const start = comment.indexOf('[');
  const end = comment.indexOf(']');

  if (start === -1 || end === -1) return <td>{comment}</td>;

  const text = comment.substring(0, start).trim();
  const items = comment
    .substring(start, end)
    .replace(/[^0-9.]+/g, ' ')
    .trim();

  const truncatedItems = items.substring(0, 26);

  return (
    <td>
      <Tooltip interactiveCustomTrigger position="leading" tooltipText={items}>
        <>
          {text}: {truncatedItems}
          {truncatedItems.length ? '...' : ''}
        </>
      </Tooltip>
    </td>
  );
};

export const UnHidden = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<null | UnHiddenItems>(null);
  const catalogId = useSelector(masterCatalogIdSelector);
  const { languageCode, retailUnit } = useParams();
  const [market, setMarket] = useState({ retailUnit, languageCode });
  const [sortKey, setSortKey] = useState<SortKeys>('eventTimestamp');

  useEffect(() => {
    const fetch = async () => {
      if (!catalogId || !market.retailUnit || !market.languageCode) return;
      const data = await dispatch(
        getUnHiddenCategories({
          catalogId,
          retailUnit: market.retailUnit,
          languageCode: market.languageCode,
        }),
      ).unwrap();
      setItems(data);
      setLoading(false);
    };

    fetch();
  }, [catalogId, market, dispatch]);

  const onSort = useCallback(
    (key: SortKeys) => {
      if (!items?.length) return;
      let sortedItems: UnHiddenItems = [];

      if (key === 'eventTimestamp') {
        sortedItems = sortOrReverseDate<UnHiddenItems[number]>(
          sortKey,
          key,
          items,
          key,
        );
      } else {
        sortedItems = sortOrReverse<UnHiddenItems[number]>(sortKey, key, items);
      }

      setItems(sortedItems);
      setSortKey(key);
    },
    [items, sortKey],
  );

  const onMarketChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [newRetailUnit, newLanguageCode] = event.target.value.split('/');

    setMarket({
      retailUnit: newRetailUnit,
      languageCode: newLanguageCode,
    });
  };

  return (
    <article className={styles.container}>
      <h2 className={styles.pageTitle}>Unhidden categories</h2>
      <section className={styles.controlPanel}>
        <MarketSelect
          id={REPORTS_SKIP_TO}
          onChange={onMarketChange}
          retailUnit={market.retailUnit || ''}
          languageCode={market.languageCode || ''}
          showGlobal={false}
        />
      </section>
      <section className={styles.dataPanel}>
        <Table fullWidth>
          <TableHeader>
            <tr>
              <th onClick={() => onSort('externalCategoryId')}>
                External Category Id
              </th>
              <th
                className={styles.clickableHeader}
                onClick={() => onSort('categoryName')}
              >
                Category Name
              </th>
              <th
                className={styles.clickableHeader}
                onClick={() => onSort('eventTimestamp')}
              >
                Date
              </th>
              <th
                className={styles.clickableHeader}
                onClick={() => onSort('username')}
              >
                Username
              </th>
              <th
                className={styles.clickableHeader}
                onClick={() => onSort('comment')}
              >
                Comment
              </th>
            </tr>
          </TableHeader>
          <TableBody striped={false}>
            {items?.map((m) => (
              <tr key={m.externalCategoryId}>
                <td>{m.externalCategoryId}</td>
                <td>{m.categoryName}</td>
                <td>{toDateTimeString(m.eventTimestamp)}</td>
                <td>{m.username}</td>
                <TruncateComment comment={m.comment} />
              </tr>
            ))}
          </TableBody>
        </Table>
        {!loading && !items?.length && (
          <small className={styles.imageNotFound}>
            No results found for the selected catalog
          </small>
        )}
      </section>
    </article>
  );
};
