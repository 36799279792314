import { Suspense, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Split from 'react-split';
import { useHotKeys } from '../../hooks/useHotKeys';
import {
  TreePanes,
  selectActivePane,
  selectPropertyPaneVisible,
  toggleActivePane,
} from '../../store/slices/pane';
import { PANE_SOURCETREE, PANE_TARGETTREE } from '../../store/constants/panes';
import { selectPanes } from '../../store/selectors/pane/data';
import PropertiesPane from '../PropertiesPane';
import TreePane from '../TreePane';
import { resetDirective } from '../../store/slices/tree';
import { analyticsEvent } from '../../store/actions/analytics/analytics';
import type { AppThunkDispatch } from '../../store';
import { ANALYTICS_TREE } from '../../store/actions/analytics/analyticsCategories';

import styles from './CatalogManager.module.scss';

const CatalogManager = function CatalogManager() {
  const dispatch = useDispatch<AppThunkDispatch>();
  const activePane = useSelector(selectActivePane);
  const panes = useSelector(selectPanes);

  const sizes = useMemo(() => {
    if (!panes.length) {
      return [100];
    }
    if (activePane) {
      return panes.map((pane) => (activePane === pane.pane ? 100 : 0));
    }
    return panes.map(() => 100 / panes.length);
  }, [activePane, panes]);
  const toggleSizeAction = useCallback(
    ([source, target]: [number, number]) => {
      if (activePane) {
        dispatch(toggleActivePane(activePane));
      } else if (source < 1) {
        dispatch(toggleActivePane(PANE_TARGETTREE));
      } else if (target < 1) {
        dispatch(toggleActivePane(PANE_SOURCETREE));
      }
    },
    [dispatch, activePane],
  );
  const spliter = useCallback((index: number, direction: string) => {
    const gutter = document.createElement('div');
    gutter.className = cx(styles.gutter, styles[direction]);
    const handle = document.createElement('span');
    handle.className = styles.handle;
    gutter.appendChild(handle);
    return gutter;
  }, []);
  const hotKeys = useMemo(
    () => ({
      Escape: () => {
        dispatch(
          analyticsEvent({
            category: ANALYTICS_TREE,
            action: 'Hotkey used',
            label: 'used hotkey: escape',
          }),
        );
        dispatch(resetDirective());
      },
    }),
    [dispatch],
  );

  useHotKeys(hotKeys);
  const propertiesPaneVisible = useSelector(selectPropertyPaneVisible);
  const showPropertiesPane = !!panes.length && propertiesPaneVisible;

  return (
    <div className={styles.content}>
      <Split
        className={cx(styles.trees, { [styles.collapsed]: showPropertiesPane })}
        gutter={spliter}
        key={panes.length}
        minSize={0}
        sizes={sizes}
        snapOffset={60}
        onDragEnd={toggleSizeAction}
      >
        {!panes.length
          ? [
              <div key="empty" className={styles.empty}>
                <h2>There is nothing to see here yet.</h2>
                <p>Please select a market to start browsing categories.</p>
              </div>,
            ]
          : panes.map(({ pane, catalog }) => {
              const active = activePane === pane;
              const collapsed = !!(activePane && !active);
              const openDetails = !showPropertiesPane && pane === PANE_TARGETTREE;
              if (!catalog) return null;
              return (
                <TreePane
                  key={pane}
                  className={cx(styles.panel, { [styles.collapsed]: collapsed })}
                  active={active}
                  catalog={catalog}
                  collapsed={collapsed}
                  openDetails={openDetails}
                  pane={pane as TreePanes}
                />
              );
            })}
      </Split>
      {showPropertiesPane && (
        <Suspense fallback={null}>
          <PropertiesPane className={styles.propertiesPanel} />
        </Suspense>
      )}
    </div>
  );
};

export default CatalogManager;
