export function sortOrReverse<T>(prevKey: string, newKey: string, items: T[]): T[] {
  const newItems = [...items];
  if (prevKey === newKey) {
    newItems.reverse();
  } else {
    newItems.sort((a, b) => {
      const first = a[newKey as keyof T];
      const second = b[newKey as keyof T];

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second);
      }

      if (typeof first === 'number' && typeof second === 'number') {
        return first - second;
      }

      return 0;
    });
  }

  return newItems as T[];
}
export function sortOrReverseDate<T>(
  prevKey: string,
  newKey: string,
  items: T[],
  property: string,
) {
  const newItems = [...items];
  if (prevKey === newKey) {
    newItems.reverse();
  } else {
    newItems.sort((a, b) => {
      const firstDate = a[property as keyof T] as string | undefined;
      const secondDate = b[property as keyof T] as string | undefined;

      return (
        new Date(secondDate ?? '').getTime() - new Date(firstDate ?? '').getTime()
      );
    });
  }
  return newItems;
}
