import { includes } from '../../../utils/array';
import { createDeepEqualResultSelector } from '../utils';
import { selectAvailableMarkets } from '.';
import { makeRetailUnitRoleName } from '../../../utils/roles/roles';
import { selectRoles } from '../../slices/roles';

export const selectMarketsGroupedPerAccess = createDeepEqualResultSelector(
  selectAvailableMarkets,
  selectRoles,
  (markets, roles) =>
    markets.reduce<{
      editorMarkets: {
        retailUnit: string;
        countryName: string;
        languageCodes: string[];
      }[];
      viewerMarkets: {
        retailUnit: string;
        countryName: string;
        languageCodes: string[];
      }[];
    }>(
      (acc, market) => {
        const isEditor = includes(roles, [
          makeRetailUnitRoleName(market.retailUnit),
        ]);
        if (isEditor) {
          acc.editorMarkets.push(market);
        } else {
          acc.viewerMarkets.push(market);
        }
        return acc;
      },
      {
        editorMarkets: [],
        viewerMarkets: [],
      },
    ),
);
