/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { orderedFind } from '../../../utils/array';
import {
  CATALOG_TYPE_AUTOMATED,
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../../../utils/types/catalogTypes';
import { catalogReceived, catalogsReceived } from '../catalog';
import {
  PANE_PROPERTIES,
  PANE_SOURCETREE,
  PANE_TARGETTREE,
} from '../../constants/panes';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';
import { createReferenceEqualSelector } from '../../selectors/utils';
import { CATALOG_STATUS_ACTIVE } from '../../../utils/statuses/catalogStatus';
import { Catalog } from '../../../entities/Catalog';
import type { RootState } from '../..';

const panes = [PANE_SOURCETREE, PANE_TARGETTREE] as const;

export type TreePanes = (typeof panes)[number];

type PaneState = {
  activePane?: TreePanes | null;
  [PANE_PROPERTIES]: boolean;
  [PANE_SOURCETREE]: Catalog | null;
  [PANE_TARGETTREE]: Catalog | null;
};

const initialState: PaneState = {
  activePane: null,
  [PANE_PROPERTIES]: true,
  [PANE_SOURCETREE]: null,
  [PANE_TARGETTREE]: null,
};

const paneSlice = createSlice({
  name: 'pane',
  initialState,
  reducers: {
    setSource(state, action: PayloadAction<{ pane: TreePanes; source: Catalog }>) {
      const { pane, source } = action.payload;
      state[pane] = source;
    },
    togglePane: {
      prepare(visible: boolean) {
        return {
          error: null,
          meta: null,
          payload: {
            visible,
          },
        };
      },
      reducer(state, action: PayloadAction<{ visible: boolean }>) {
        const { visible } = action.payload;
        state[PANE_PROPERTIES] = visible;
      },
    },
    toggleActivePane(state, action: PayloadAction<TreePanes>) {
      const pane = action.payload;
      state.activePane = state.activePane === pane ? null : pane;
    },
    resetPanes() {
      return initialState;
    },
  },

  extraReducers: (builder) =>
    mergeStateReducerBuilder<PaneState>('state.pane', builder)
      .addCase(catalogReceived, (state, action: PayloadAction<Catalog>) => {
        const catalog = action.payload;
        if (
          state[PANE_SOURCETREE] &&
          state[PANE_SOURCETREE]?.catalogId === catalog?.catalogId
        ) {
          state[PANE_SOURCETREE] = catalog;
        }
        if (
          state[PANE_TARGETTREE] &&
          state[PANE_TARGETTREE].catalogId === catalog?.catalogId
        ) {
          state[PANE_TARGETTREE] = catalog;
        }
      })
      .addCase(catalogsReceived, (state, action) => {
        const catalogs = action.payload;
        const inactiveTypes = [
          CATALOG_TYPE_AUTOMATED,
          CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
          CATALOG_TYPE_UNCATEGORIZED,
        ];
        const predicate = (c: Catalog, t: string) =>
          c.catalogType === t && c.catalogStatus === CATALOG_STATUS_ACTIVE;
        const sourceTree = orderedFind(
          catalogs,
          predicate,
          CATALOG_TYPE_UNCATEGORIZED,
          CATALOG_TYPE_MASTER,
        );
        const targetTree =
          catalogs.find(
            (f: Catalog) =>
              f.catalogType === CATALOG_TYPE_MASTER &&
              f.catalogStatus === CATALOG_STATUS_ACTIVE,
          ) || null;

        state.activePane = catalogs.some((c: Catalog) =>
          inactiveTypes.includes(c.catalogType),
        )
          ? null
          : PANE_TARGETTREE;

        state[PANE_SOURCETREE] = sourceTree ?? null;
        state[PANE_TARGETTREE] = targetTree;
      }),
});

export const { setSource, toggleActivePane, togglePane, resetPanes } =
  paneSlice.actions;
export default paneSlice.reducer;

const paneStateSelector = (state: RootState) => state.pane;

export const selectActivePane = createReferenceEqualSelector(
  paneStateSelector,
  (state) => state.activePane,
);

export const selectPropertyPaneVisible = createReferenceEqualSelector(
  paneStateSelector,
  (state) => state[PANE_PROPERTIES],
);
export const selectSourcePane = createReferenceEqualSelector(
  paneStateSelector,
  (state) => state[PANE_SOURCETREE],
);
export const selectTargetPane = createReferenceEqualSelector(
  paneStateSelector,
  (state) => state[PANE_TARGETTREE],
);
