import { ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit';
import { analyticsEvent } from '../../actions/analytics/analytics';
import { setMarket } from '.';
import type { AppDispatch, RootState } from '../..';

export const setMarketListenerOptions = {
  actionCreator: setMarket,
  effect: (
    action: PayloadAction<{ retailUnit: string; languageCode: string }>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    const { dispatch } = listenerApi;
    const { retailUnit, languageCode } = action.payload;

    dispatch(
      analyticsEvent({
        category: 'Market Selector',
        action: 'Change market',
        label: `Switched to ${retailUnit} with language code ${languageCode}`,
      }),
    );
  },
};
