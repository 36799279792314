import { uniq, xor } from 'lodash-es';
import { noOperation } from '../function';
import { ReadOnlyArray } from './ReadOnlyArray';

export { orderBy } from 'lodash-es';

export function arrayify(data, allowNull) {
  // eslint-disable-next-line no-nested-ternary
  return Array.isArray(data)
    ? data
    : typeof data !== 'undefined' && (data !== null || allowNull)
      ? [data]
      : ReadOnlyArray.Empty;
}

export function deArrayify(data) {
  return Array.isArray(data) && data.length === 1 ? data[0] : data;
}

export function isEqualByEntries(left, right) {
  return xor(left, right).length === 0;
}

export function orderedFind(source, predicate, ...toMatch) {
  const items = Array.isArray(source) ? source : ReadOnlyArray.Empty;
  const toFindOrder = toMatch.length ? toMatch : [undefined];
  const matcher = typeof predicate === 'function' ? predicate : noOperation;
  let found;
  toFindOrder.some((toFind) => {
    found = items.find((item) => matcher(item, toFind));
    return !!found;
  });
  return found;
}

export function unique(array) {
  return uniq(array);
}

export function includes(sourceArray, toTestForArray) {
  return toTestForArray.every((i) => sourceArray.includes(i));
}

export function getCyclicIndex(array, index) {
  // eslint-disable-next-line no-nested-ternary
  const cyclicIndex =
    index < 0 ? array.length - 1 : index >= array.length ? 0 : index;
  return cyclicIndex;
}
