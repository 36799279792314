/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { without } from '../../../utils/object';
import { API_FILE_REQUEST, API_REQUEST } from '../../constants/api';
import { createReferenceEqualSelector } from '../../selectors/utils';
import type { RootState } from '../..';

interface OngoingRequestState {
  [key: string]: string;
}

const ongoingrequestsSlice = createSlice({
  name: 'ongoingrequests',
  initialState: {} as OngoingRequestState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        (action) =>
          action.type.match(/^api\/request\/(?:delete|get|put|post|file)\/pending$/),
        (
          state,
          action: PayloadAction<
            unknown,
            string,
            { requestId: string; requestType: string }
          >,
        ) => {
          state[action.meta.requestId] = action.meta.requestType;
        },
      )
      .addMatcher(
        (action) =>
          action.type.match(
            /^api\/request\/(?:delete|get|put|post|file)\/(?:fulfilled|rejected)$/,
          ),
        (state, action: PayloadAction<unknown, string, { requestId: string }>) =>
          without(current(state), action.meta.requestId),
      ),
});

export default ongoingrequestsSlice.reducer;

const onGoingSelector = (state: RootState) => state.ongoingrequests;

export const selectHasOngoingApiRequest = createReferenceEqualSelector(
  onGoingSelector,
  (state) => Object.values(state).filter((type) => type === API_REQUEST).length > 0,
);
export const selectHasOngoingExports = createReferenceEqualSelector(
  onGoingSelector,
  (state) =>
    Object.values(state).filter((type) => type === API_FILE_REQUEST).length > 0,
);
