import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectMarketProps } from '../../selectors/market';
import { DELETE, POST } from '.';
import type { RootState } from '../..';

export const createRecommendedCatalog = createAsyncThunk(
  'catalog/createRecommendedCatalog',
  async ({ catalogName }: { catalogName: string }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      POST({
        ignoreError: true,
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/ab-test?catalogue-name=${catalogName}`,
        label: `Create AB Test Catalogue`,
      }),
    );
  },
);

export const deleteRecommendedCatalog = createAsyncThunk(
  'catalog/deleteRecommendedCatalog',
  async ({ catalogId }: { catalogId: string }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      DELETE({
        ignoreError: true,
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/ab-test/${catalogId}`,
        label: `Delete AB Catalogue with id:${catalogId}`,
      }),
    );
  },
);
