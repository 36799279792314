import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  search,
  searchClear,
  searchMoveNext,
  searchMovePrevious,
  searchSetConditionByFlag,
  searchSetConditionByText,
  searchToggle,
} from '../../../store/actions/tree';
import {
  selectSearchConditionForTree,
  selectSearchFocusIndexForTree,
  selectSearchItemHit,
  selectSearchMatchesCountForTree,
  selectSearchQueryForTree,
  selectSearchSuggestionsForTree,
} from '../../../store/selectors/tree';
import {
  selectSearchAvailableFlags,
  selectSearchCatalogList,
} from '../../../store/selectors/tree/search';
import { formatItemNo } from '../../../utils/data/utils';
import { SHAPE_SELECTED_CATALOG } from '../../../utils/shapes/catalog';
import {
  SHAPE_SEARCH_CONDITION,
  SHAPE_SEARCH_SUGGESTION,
} from '../../../utils/shapes/search';
import { SEARCH_BY } from '../../../utils/tree/search';
import CatalogStatus from '../../CatalogStatus';

import arrowRight from '@ingka/ssr-icon/paths/arrow-right';
import chrevronDown from '@ingka/ssr-icon/paths/chevron-down-small';
import chrevronUp from '@ingka/ssr-icon/paths/chevron-up-small';
import crossSmall from '@ingka/ssr-icon/paths/cross-small';
import cross from '@ingka/ssr-icon/paths/cross-small';
import chrevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import magnifyingGlass from '@ingka/ssr-icon/paths/magnifying-glass';

import CatalogIcon from '../../Icons/CatalogIcon';
import ItemNoPlate from '../../ItemNoPlate';
import Flags from '../../StatusBadges/mappings';
import styles from './SearchPanel.module.scss';
import SearchPanelButton from './SearchPanelButton';
import { useDebounceValue } from '../../../hooks/useDebounceValue';
import { analyticsEvent } from '../../../store/actions/analytics/analytics';
import { ANALYTICS_SEARCH } from '../../../store/actions/analytics/analyticsCategories';
import { isLoadingSelector } from '../../../store/slices/pond';
import ReasonCodes from '../../Tree/NodeContent/SmallBanner/banners/ReasonCodes';
import SSRIcon from '@ingka/ssr-icon';

const SearchComponent = ({
  tree,
  availableFlags,
  catalogList,
  hasSearchResult,
  isSearching,
  item,
  numberOfSearchResults,
  searchCondition,
  searchFocusIndex,
  selectableSuggestions,
  structureReasonText,
  suggestions,
}) => {
  const inputRef = useRef('');
  const wrapperRef = useRef(null);
  const activeTimer = useRef(null);
  const [active, setActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const debouncedValue = useDebounceValue(searchText, 300);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (debouncedValue.length) {
      dispatch(searchSetConditionByText(tree, debouncedValue));
    }
  }, [debouncedValue, tree, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        hideSuggestions();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const focusInput = (clear) => {
    if (!inputRef.current) {
      return;
    }
    if (clear) {
      inputRef.current.value = '';
    }
    inputRef.current.focus();
  };

  const setInput = (text) => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = text;
    inputRef.current.focus();
  };

  const getSelectedSuggestion = () => {
    const selected = selectableSuggestions[selectedIndex];

    if (!selected) {
      return null;
    }
    if (selected.type === SEARCH_BY.FLAG) {
      return selected.id;
    }
    return suggestions.find((s) => s.id === selected.id);
  };

  const hideSuggestions = () => {
    if (activeTimer.current) clearTimeout(activeTimer.current);
    activeTimer.current = setTimeout(() => setActive(false), 200);
  };

  const showSuggestions = () => {
    clearTimeout(activeTimer.current);
    setActive(true);
  };

  const onClearBtnClick = () => {
    dispatch(searchClear(tree, false));
    focusInput(true);
  };

  const onCloseBtnClick = () => {
    dispatch(searchToggle(tree, false));
  };

  const highlightNextSuggestion = () => {
    const nextSelectedIndex = selectedIndex + 1;
    if (!selectableSuggestions.length) {
      return;
    }
    setSelectedIndex(
      nextSelectedIndex === selectableSuggestions.length ? 0 : nextSelectedIndex,
    );
  };

  const highlightPrevSuggestion = () => {
    const prevSelectedIndex = selectedIndex - 1;
    if (!selectableSuggestions.length) {
      return;
    }
    setSelectedIndex(
      prevSelectedIndex === -1
        ? selectableSuggestions.length - 1
        : prevSelectedIndex,
    );
  };

  const onType = (e) => {
    setSearchText(e.target.value);
    setSelectedIndex(-1);
  };

  const onSearch = () => {
    dispatch(search(tree));
    inputRef.current?.focus();
  };

  const selectNextSearchHit = () => {
    dispatch(
      analyticsEvent({
        category: ANALYTICS_SEARCH,
        action: 'Select next',
        label: 'Search Select previous',
      }),
    );
    dispatch(searchMoveNext(tree));
  };

  const selectPreviousSearchHit = () => {
    dispatch(
      analyticsEvent({
        category: ANALYTICS_SEARCH,
        action: 'Select previous',
        label: 'Search Select previous',
      }),
    );

    dispatch(searchMovePrevious(tree));
  };

  const setSearchByFlag = (flag) => {
    dispatch(searchSetConditionByFlag(tree, flag));
    setSelectedIndex(-1);
    onSearch();
    setActive(false);
  };

  const setSearchByText = (text) => {
    dispatch(searchSetConditionByText(tree, text));
    setInput(text);
    setSelectedIndex(-1);
    onSearch();
    setActive(false);
  };

  const selectSuggestion = () => {
    const selected = getSelectedSuggestion();
    if (suggestions.includes(selected)) {
      // eslint-disable-next-line react/prop-types
      setSearchByText(selected.title);
    } else if (Object.prototype.hasOwnProperty.call(availableFlags, selected)) {
      setSearchByFlag(selected);
    }
    setSelectedIndex(-1);
  };

  const onKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      highlightNextSuggestion();
    } else if (e.key === 'ArrowUp') {
      highlightPrevSuggestion();
    } else if (e.key === 'Enter') {
      if (selectedIndex < 0) {
        if (searchCondition?.condition) {
          onSearch();
        }
      } else {
        selectSuggestion();
      }
    } else if (e.key === 'Escape') {
      onCloseBtnClick();
    } else {
      return true;
    }
    e.stopPropagation();
    e.preventDefault();
    e.returnValue = false;
    e.cancelBubble = true;
    return false;
  };

  const renderFlag = (flag) => {
    const Flag = Flags[flag];
    if (!Flag) {
      return <div>N/A</div>;
    }
    return <Flag />;
  };

  const emptyResult =
    hasSearchResult && !numberOfSearchResults && !catalogList.length;

  const selectedSuggestion = getSelectedSuggestion();
  const disableNavigation = !numberOfSearchResults;
  const displayFlagContainer = searchCondition?.by === SEARCH_BY.FLAG;
  const placeholder = active ? 'Find item or category name/ID' : 'Find';

  return (
    <div ref={wrapperRef} className={cx(styles.panel, { [styles.active]: active })}>
      <div className={styles.wrapper}>
        <div className={styles.searchComponent}>
          <SearchPanelButton
            className={styles.curvedLeft}
            onClick={onCloseBtnClick}
            iconPath={cross}
            label="Close search"
          />
          <div
            className={cx(styles.inputWrapper, {
              [styles.empty]: !searchCondition,
            })}
          >
            <input
              ref={inputRef}
              className={cx({ [styles.hidden]: displayFlagContainer })}
              type="text"
              placeholder={displayFlagContainer ? '' : placeholder}
              onChange={onType}
              onFocus={showSuggestions}
              onKeyDown={onKeyDown}
            />
            {displayFlagContainer && (
              <div className={styles.flagContainer}>
                {renderFlag(searchCondition.condition)}
              </div>
            )}
          </div>
          {!!searchCondition && (
            <>
              <SearchPanelButton
                onClick={onClearBtnClick}
                iconPath={crossSmall}
                label="Clear search"
                disabled={!searchCondition}
              />
              {!hasSearchResult && (
                <>
                  <div className={styles.divider} />
                  <SearchPanelButton
                    className={styles.curvedRight}
                    onClick={onSearch}
                    iconPath={arrowRight}
                    label="Perform search"
                    disabled={!searchCondition}
                  />
                </>
              )}
            </>
          )}
          {hasSearchResult && (
            <div
              className={cx(styles.nbrOfHits, {
                [styles.active]: !disableNavigation,
              })}
            >
              {`${
                searchFocusIndex === null || searchFocusIndex < 0
                  ? 0
                  : searchFocusIndex + 1
              }/${numberOfSearchResults}`}
            </div>
          )}
          {hasSearchResult && (
            <>
              <SearchPanelButton
                className={cx({ [styles.active]: !disableNavigation })}
                onClick={selectPreviousSearchHit}
                iconPath={chrevronUp}
                label="Select previous"
                disabled={disableNavigation}
              />
              <SearchPanelButton
                className={cx(
                  { [styles.active]: !disableNavigation },
                  styles.curvedRight,
                )}
                onClick={selectNextSearchHit}
                iconPath={chrevronDown}
                label="Select next"
                disabled={disableNavigation}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.suggestions}>
        {isSearching && <span className={styles.searching}>Searching...</span>}
        {!isSearching && emptyResult ? (
          <p className="u-margin-large--bottom">
            {structureReasonText ? (
              <>
                <ItemNoPlate itemNoFormated={formatItemNo(item.itemNo)} />
                <strong>Not valid for structure</strong>
                {` - ${structureReasonText}`}
                <br />
                <ReasonCodes />
              </>
            ) : (
              `No hits for "${searchCondition?.condition}"`
            )}{' '}
          </p>
        ) : (
          catalogList.length > 0 && (
            <div className="u-margin-large--bottom">
              <p>
                <ItemNoPlate itemNoFormated={formatItemNo(item.itemNo)} /> was found
                in:
              </p>
              <ul className={styles.catalogs}>
                {catalogList.map((catalog) => (
                  <li key={catalog.catalogId}>
                    <CatalogIcon catalog={catalog} /> {catalog.catalogName}
                    <CatalogStatus catalog={catalog} />
                  </li>
                ))}
              </ul>
              <p className={styles.hint}>
                We cant send you there, but you can try switching catalogue and do a
                new search
              </p>
            </div>
          )
        )}
        {!!suggestions.length && (
          <ul className={styles.suggestionGroup}>
            {suggestions.map((suggestion, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={key}>
                <button
                  aria-label={suggestion.title}
                  className={cx(styles.suggestion, {
                    [styles.selected]: selectedSuggestion === suggestion,
                  })}
                  onMouseEnter={() => setSelectedIndex(key)}
                  onMouseLeave={() => setSelectedIndex(-1)}
                  onClick={() => {
                    setSearchByText(suggestion.title);
                  }}
                  type="button"
                >
                  <SSRIcon paths={magnifyingGlass} />
                  <span className={styles.title}>{suggestion.title}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
        {Object.keys(availableFlags).length > 0 && (
          <>
            <div>Find a tag</div>
            <ul
              aria-label="tags"
              className={cx(styles.suggestionGroup, {
                [styles.active]: active,
              })}
            >
              {Object.entries(availableFlags).map(([flag, count], i) => (
                <li key={flag}>
                  <button
                    aria-label={flag}
                    className={cx(styles.suggestion, {
                      [styles.selected]: selectedSuggestion === flag,
                    })}
                    onClick={() => {
                      dispatch(
                        analyticsEvent({
                          category: ANALYTICS_SEARCH,
                          action: `Tag clicked`,
                          label: `Tag ${flag} clicked`,
                        }),
                      );
                      setSearchByFlag(flag);
                    }}
                    onMouseEnter={() => setSelectedIndex(suggestions.length + i)}
                    onMouseLeave={() => setSelectedIndex(-1)}
                    type="button"
                  >
                    <SSRIcon paths={chrevronRight} />
                    {renderFlag(flag)}
                    <span className={styles.count}>{count}</span>
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

SearchComponent.propTypes = {
  availableFlags: PropTypes.shape({}).isRequired,
  catalogList: PropTypes.arrayOf(SHAPE_SELECTED_CATALOG.isRequired),
  hasSearchResult: PropTypes.bool,
  isSearching: PropTypes.bool,
  item: PropTypes.shape({
    itemNo: PropTypes.string.isRequired,
  }),
  numberOfSearchResults: PropTypes.number,
  searchCondition: SHAPE_SEARCH_CONDITION,
  searchFocusIndex: PropTypes.number,
  selectableSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  suggestions: PropTypes.arrayOf(SHAPE_SEARCH_SUGGESTION).isRequired,
  structureReasonText: PropTypes.string,
  tree: PropTypes.string.isRequired,
};

const SearchPanel = function SearchPanel({ tree }) {
  const catalogList = useSelector((state) => selectSearchCatalogList(state, tree));
  const suggestions = useSelector((state) =>
    selectSearchSuggestionsForTree(state, tree),
  );
  const availableFlags = useSelector((state) =>
    selectSearchAvailableFlags(state, tree),
  );
  const selectableSuggestions = useMemo(
    () => [
      ...suggestions.map(({ id, type }) => ({ id, type })),
      ...Object.keys(availableFlags).map((flag) => ({
        type: SEARCH_BY.FLAG,
        id: flag,
      })),
    ],
    [availableFlags, suggestions],
  );

  const searchCondition = useSelector((state) =>
    selectSearchConditionForTree(state, tree),
  );
  const searchQuery = useSelector((state) => selectSearchQueryForTree(state, tree));

  const structureReasonText = useMemo(
    () => searchQuery?.structureReasonText,
    [searchQuery],
  );
  const isSearching = useSelector((state) =>
    isLoadingSelector(state, searchCondition?.condition),
  );
  const item = useSelector((state) => selectSearchItemHit(state, tree));
  const numberOfSearchResults = useSelector((state) =>
    selectSearchMatchesCountForTree(state, tree),
  );
  const searchFocusIndex = useSelector((state) =>
    selectSearchFocusIndexForTree(state, tree),
  );

  return (
    <SearchComponent
      availableFlags={availableFlags}
      catalogList={catalogList}
      hasSearchResult={!!searchQuery}
      isSearching={isSearching}
      item={item}
      numberOfSearchResults={numberOfSearchResults}
      searchCondition={searchCondition}
      searchFocusIndex={searchFocusIndex}
      selectableSuggestions={selectableSuggestions}
      structureReasonText={structureReasonText}
      suggestions={suggestions}
      tree={tree}
    />
  );
};

SearchPanel.propTypes = {
  tree: PropTypes.string.isRequired,
};

export default SearchPanel;
