import { PANE_SOURCETREE } from '../../store/constants/panes';
import { selectPanes } from '../../store/selectors/pane/data';
import { selectTreeState } from '../../store/selectors/tree/data';
import { selectedTreeNodeSelector } from '../../store/selectors/tree/node';
import {
  selectCurrentLanguageCode,
  selectCurrentRetailUnit,
} from '../../store/slices/intl';
import {
  selectActivePane,
  selectPropertyPaneVisible,
} from '../../store/slices/pane';
import { pipeline } from '../operations/pipeline';
import { addParam } from './search';
import { createUrl } from './utils';

export function makeUrlFromState(state) {
  const retailUnit = selectCurrentRetailUnit(state);
  const languageCode = selectCurrentLanguageCode(state);
  const catalogs = getCatalogs(selectPanes(state));
  const href = pipeline(
    createUrl(`/${retailUnit}/${languageCode}`),
    add('catalogues', catalogs),
    add('activepane', selectActivePane(state) ?? ''),
    add('details', `${selectPropertyPaneVisible(state)}`),
    add('selectedNode', selectedTreeNodeSelector(state)?.node?.id ?? ''),
    ...catalogs
      .filter((c) => !!c)
      .map((c) => add(`exp_${c}`, selectTreeState(state, c))),
  );
  return href;
}

function add(...params) {
  return (url) => addParam(url, ...params);
}

function getCatalogs(panes) {
  return panes.reduce(
    (a, p) => {
      // eslint-disable-next-line no-param-reassign
      a[p.pane === PANE_SOURCETREE ? 0 : 1] = p.catalog.catalogId;
      return a;
    },
    ['', ''],
  );
}
