/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { without } from '../../../utils/object';
import { STEP_PREVIEW, STEP_START } from '../../constants/importitems';
import { createReferenceEqualSelector } from '../../selectors/utils';
import type { RootState } from '../..';

const steps = [STEP_START, STEP_PREVIEW];

interface ImportItemState {
  step?: string;
  disabled?: boolean;
  catalogId?: string;
  categoryId?: string;
  inputValue?: string;
  previewResult?: {
    notFoundItems: string[];
    invalidItems: string[];
    oldItems: string[];
    notValidForStructureItems: string[];
    relationAlreadyExistsItems: string[];
    toBeImportedItems: {
      itemNo: string;
      itemType: string;
      itemName: string;
      countrySpecificItemCount: number;
    }[];
  };
  preview?: {
    itemNumbers: string[];
    invalid: string[];
  };
}

const initialState: ImportItemState = {};
const importItemSlice = createSlice({
  name: 'importitems',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    disable(state) {
      return { ...state, disabled: true };
    },
    enable(state) {
      return without(state, 'disabled');
    },
    preview(state, action) {
      const { itemNumbers, invalid } = action.payload;
      return { ...state, preview: { itemNumbers, invalid }, step: STEP_PREVIEW };
    },
    previousStep(state) {
      if (!state.step) return state;

      const idx = steps.indexOf(state.step) - 1;
      const step = steps[idx < 0 ? 0 : idx];
      state.step = step;
      return state;
    },
    setInputValue(state, action) {
      state.inputValue = action.payload;
      return state;
    },
    setPreviewResult(state, action) {
      const { preview } = action.payload;
      state.previewResult = preview;
    },
    start(state, action) {
      const { catalogId, categoryId } = action.payload?.node || {};
      return {
        ...initialState,
        catalogId,
        categoryId,
        step: STEP_START,
      };
    },
  },
});

export const {
  clear,
  disable,
  enable,
  preview,
  previousStep,
  setInputValue,
  setPreviewResult,
  start,
} = importItemSlice.actions;
export default importItemSlice.reducer;

const createImportItemsSelector = (state: RootState) => state.importitems;

export const selectCurrentStep = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.step,
);
export const selectDisabledState = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.disabled,
);
export const selectImportToCatalogId = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.catalogId,
);
export const selectImportToCategoryId = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.categoryId,
);
export const selectInputValue = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.inputValue || '',
);
export const selectPreviewResult = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state.previewResult,
);
export const selectItemsExist = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.relationAlreadyExistsItems || ReadOnlyArray.Empty,
);
export const selectItemsNotFound = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.notFoundItems || ReadOnlyArray.Empty,
);
export const selectItemsNotValidForStructure = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.notValidForStructureItems || ReadOnlyArray.Empty,
);
export const selectItemsToBeImported = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.toBeImportedItems || ReadOnlyArray.Empty,
);
export const selectInvalidItemsParsed = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.preview?.invalid || ReadOnlyArray.Empty,
);
export const selectOldItems = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.oldItems || ReadOnlyArray.Empty,
);

export const selectInvalidItemsLookup = createReferenceEqualSelector(
  createImportItemsSelector,
  (state) => state?.previewResult?.invalidItems || ReadOnlyArray.Empty,
);
