import { StrictMode, Suspense, lazy } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import { useAnalyticsInitialize } from './hooks/useAnalyticsInitialize';
import Header from './components/Header/Header';
import CatalogManager from './components/CatalogManager';
import { UnHidden } from './components/Reports/UnHidden/UnHidden';
import Auth0Wrapper from './components/Auth0Wrapper';
import MiddlewareWrapper from './components/MiddlewareWrapper/MiddlewareWrapper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { selectAppIsLoading } from './store/slices/app';
import { useSelector } from 'react-redux';
import GlobalGuard from './components/GlobalGuard';
import 'focus-visible';
import {
  ROUTE_AUTOCATEGORIZED,
  ROUTE_HOME,
  ROUTE_IMAGE_OVERRIDES,
  ROUTE_ITEM_GROPUPING,
  ROUTE_NVFS,
  ROUTE_UNHIDDEN,
} from './routes';

const Toaster = lazy(() => import('./components/Toaster'));
const ModalManager = lazy(() => import('./components/ModalManager'));
const AutoCategorized = lazy(() => import('./components/Reports/AutoCategorized'));
const ItemGrouping = lazy(() => import('./components/Reports/ItemGrouping'));
const ImageOverrides = lazy(() => import('./components/Reports/ImageOverrides'));
const NotValidForStructureItems = lazy(
  () => import('./components/Reports/NotValidForStructureItemReports'),
);

const App = function App() {
  useAnalyticsInitialize();
  const loadingApplication = useSelector(selectAppIsLoading);

  return (
    <StrictMode>
      <ErrorBoundary>
        <MiddlewareWrapper>
          <Auth0Wrapper>
            <BrowserRouter>
              <Header />
              <Suspense>
                {!loadingApplication && (
                  <Routes>
                    <Route
                      path={ROUTE_AUTOCATEGORIZED}
                      element={
                        <GlobalGuard>
                          <AutoCategorized />
                        </GlobalGuard>
                      }
                    />
                    <Route path={ROUTE_UNHIDDEN} element={<UnHidden />} />
                    <Route
                      path={ROUTE_IMAGE_OVERRIDES}
                      element={<ImageOverrides />}
                    />
                    <Route
                      path={ROUTE_ITEM_GROPUPING}
                      element={
                        <GlobalGuard>
                          <ItemGrouping />
                        </GlobalGuard>
                      }
                    />
                    <Route
                      path={ROUTE_NVFS}
                      element={
                        <GlobalGuard>
                          <NotValidForStructureItems />
                        </GlobalGuard>
                      }
                    />
                    <Route path={ROUTE_HOME} element={<CatalogManager />}></Route>
                  </Routes>
                )}
              </Suspense>
              <Suspense>
                <ModalManager />
                <Toaster />
              </Suspense>
            </BrowserRouter>
          </Auth0Wrapper>
        </MiddlewareWrapper>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
