import { createAsyncThunk } from '@reduxjs/toolkit';
import { objToQueryString } from '../../../utils/url/utils';
import { SORT_ORDER } from '../reports/notvalidforstrucutreitems/constants';
import { GET } from '.';
import {
  ItemGroupingReportsSchema,
  NotValidForStructureReportsSchema,
} from '../../../schemas/items';
import { GLOBAL } from '../../../utils/market';
import { AutoUnHiddenCategoriesReportsSchema } from '../../../schemas/categories';

interface FilterData {
  pageNo: number;
  pageSize: number;
  sortBy: string;
  orderBy: string;
  filteredOn?: string;
}

export const getItemGrouping = createAsyncThunk(
  'reports/itemgrouping/get',
  async (_, thunkApi) => {
    const retailUnit = GLOBAL;
    const languageCode = GLOBAL;
    const { dispatch } = thunkApi;

    const responseData = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/items/grouping-statistics`,
        label: `Get reports for item grouping`,
      }),
    );

    const parsedData = ItemGroupingReportsSchema.parse(responseData);

    return parsedData;
  },
);

export const getNotValidForStructureItemReports = createAsyncThunk(
  'reports/notvalidforstructureitems/get',
  async (
    {
      fromDate,
      toDate,
      pageNo = 0,
      sortBy = 'UPDATED_DATE',
      sortOrder = SORT_ORDER.DESC,
      filter,
    }: {
      fromDate: string;
      toDate: string;
      pageNo: number;
      sortBy: string;
      sortOrder: string;
      filter: string;
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;
    const retailUnit = 'ZZ';
    const languageCode = 'en';
    const data = {
      pageNo,
      pageSize: 100,
      sortBy,
      orderBy: sortOrder,
    } as FilterData;

    if (filter) {
      data.filteredOn = filter;
    }

    const responseData = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/not-valid-for-structure-items-status-changes/${fromDate}/${toDate}?${objToQueryString(
          data,
        )}`,
        data,
        label: `Fetch Not valid for structure items reports ${fromDate}-${toDate}`,
      }),
    );
    const parsedData = NotValidForStructureReportsSchema.parse(responseData);

    return parsedData;
  },
);

export const getUnHiddenCategories = createAsyncThunk(
  'reports/autounhidden/get',
  async (
    {
      catalogId,
      languageCode,
      retailUnit,
    }: { catalogId: string; languageCode: string; retailUnit: string },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;

    const responseData = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/unhidden`,
        label: `get unhidden categories for ${retailUnit}/${languageCode}`,
      }),
    );

    const parsedData = AutoUnHiddenCategoriesReportsSchema.parse(responseData);

    return parsedData;
  },
);
