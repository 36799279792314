export const ROUTE_AUTOCATEGORIZED =
  '/:retailUnit/:languageCode/reports/AutoCategorized';
export const ROUTE_UNHIDDEN = '/:retailUnit/:languageCode/reports/UnHidden';
export const ROUTE_IMAGE_OVERRIDES =
  '/:retailUnit/:languageCode/reports/ImageOverrides';
export const ROUTE_ITEM_GROPUPING =
  '/:retailUnit/:languageCode/reports/ItemGrouping';
export const ROUTE_NVFS =
  '/:retailUnit/:languageCode/reports/NotValidForStructureItems';

export const ROUTE_HOME = '/:retailUnit?/:languageCode?';

export const REPORT_ROUTES = [
  {
    path: ROUTE_AUTOCATEGORIZED,
  },
  {
    path: ROUTE_IMAGE_OVERRIDES,
  },
  {
    path: ROUTE_ITEM_GROPUPING,
  },
  {
    path: ROUTE_UNHIDDEN,
  },
  {
    path: ROUTE_NVFS,
  },
];
